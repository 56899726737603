import { graphql, PageProps } from "gatsby"
import Page from "@components/Account/Change/AccountChange"

export type Props = PageProps<GatsbyTypes.PageAccountChangeQuery, GatsbyTypes.PageAccountChangeQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageAccountChange {
    page: sanityPageAccountChange {
      title
      url
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      content: _rawContent(resolveReferences: { maxDepth: 2 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} />
export default Component
