import { memo, useEffect } from "react"
import { Button, Stack, Text } from "@chakra-ui/react"
import { useCustomerRecover } from "@app/hooks/useCustomer"
import { useFormBuilder, FunctionErrors } from "@app/hooks/useFormBuilder"
import { useCustomerContext } from "@app/providers/customer"

type AccountChangeFormProps = {
  buttonTitle?: string
}

const AccountChangeForm: React.FC<AccountChangeFormProps> = ({ buttonTitle }) => {
  const { handleChange, handleSubmit: sendChangeEmail, loading, errors: functionErrors, success } = useCustomerRecover()
  const { customer } = useCustomerContext()

  const { handleSubmit } = useFormBuilder({
    onSubmit: (data: any, event: any) => sendChangeEmail(event),
  })

  useEffect(() => {
    if (customer?.email) {
      // @ts-ignore-next-line
      handleChange({ target: { name: "email", value: customer?.email } })
    }
  }, [customer, handleChange])

  return (
    <Stack as="form" onSubmit={handleSubmit} spacing={4}>
      {buttonTitle && (
        <Button type="submit" isDisabled={loading} isLoading={loading}>
          {buttonTitle}
        </Button>
      )}

      <FunctionErrors errors={functionErrors} />
      {/* TODO: add to locales */}
      {success && <Text fontWeight={700}>Email Sent ✓</Text>}
    </Stack>
  )
}

const MemoAccountChangeForm = memo(AccountChangeForm)
export { MemoAccountChangeForm as AccountChangeForm }
